<template>
  <Portlet
    :title="$t('customerWorklogReport')"
    icon="table"
  >
    <div slot="buttons">
      <button
        v-if="issueResult && Object.keys(issueResult).length > 0"
        class="btn btn-sm btn-primary ml-2 float-right"
        :disabled="exportLoading"
        @click="excelExport(true)"
      >
        <font-awesome-icon
          v-if="exportLoading"
          :class="[{ 'fa-spin': exportLoading }]"
          icon="sync-alt"
        />
        <template v-else>
          <font-awesome-icon
            class="mr-1"
            icon="file-export"
          />
          <span>
            {{ "Export Tasks Excel" }}
          </span>
        </template>
      </button>
      <button
        v-if="issueResult && Object.keys(issueResult).length > 0"
        class="btn btn-sm btn-primary ml-2 float-right"
        :disabled="exportLoading"
        @click="excelExport(false)"
      >
        <font-awesome-icon
          v-if="exportLoading"
          :class="[{ 'fa-spin': exportLoading }]"
          icon="sync-alt"
        />
        <template v-else>
          <font-awesome-icon
            class="mr-1"
            icon="file-export"
          />
          <span>
            {{ "Export Excel" }}
          </span>
        </template>
      </button>
      <button
        v-if="issueResult && Object.keys(issueResult).length > 0"
        class="btn btn-sm btn-primary ml-2 float-right"
        @click="pdfExport(true)"
      >
        <font-awesome-icon
          class="mr-1"
          icon="file-export"
        />
        <span>
          {{ "Export Tasks PDF" }}
        </span>
      </button>
      <button
        v-if="issueResult && Object.keys(issueResult).length > 0"
        class="btn btn-sm btn-primary ml-2 float-right"
        @click="pdfExport(false)"
      >
        <font-awesome-icon
          class="mr-1"
          icon="file-export"
        />
        <span>
          {{ "Export PDF" }}
        </span>
      </button>
      <button
        class="btn btn-sm btn-primary float-right"
        @click="generateUrl()"
      >
        Generate URL
      </button>
    </div>
    <LoadingPlaceholder v-if="reportData" />
    <template v-if="reportData">
      <div class="progress mt-3">
        <div
          class="progress-bar"
          role="progressbar"
          :style="{ width: reportData.percentage + '%' }"
          :aria-valuenow="reportData.percentage"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {{ reportData.percentage + "%" }}
        </div>
      </div>
    </template>
    <div
      v-else
      class="customerWorklogReport"
    >
      <LoadingPlaceholder v-if="componentLoading" />
      <template v-else>
        <form>
          <toggle-button
            id="multipleButton"
            v-model="multipleComponentsToggle"
            :labels="{ checked: 'merge', unchecked: 'single' }"
            :color="{ checked: 'gray', unchecked: 'green' }"
            :width="80"
            :height="30"
            :font-size="10"
            class="mb-3"
            @change="changeMultipleComponentsToggle()"
          />
          <div
            v-if="!multipleComponentsToggle"
            class="form-group col-6"
          >
            <label
              for="components"
              class="form-label"
            >Component
              <b>(Choose "Customer Support Component" or "Support Non-billable"
                or "Support Hour Package")</b></label>
            <label
              for="components"
              class="form-label"
            >Definition of Support Non-billable: This report excludes issues
              with components like Support, SPOC, Project (those are
              billable)</label>
            <Multiselect
              id="components"
              v-model="component"
              tag-placeholder="Not found"
              placeholder="Select a Component"
              :options="components"
              select-label=""
              :multiple="false"
              :taggable="true"
              :hide-selected="true"
              :searchable="true"
              :close-on-select="true"
              class="mb-4"
            />
          </div>
          <div
            v-else
            class="form-group col-6"
          >
            <label
              for="masterComponent"
              class="form-label"
            >Title Component</label>
            <Multiselect
              id="masterComponent"
              v-model="masterComponent"
              tag-placeholder="Not found"
              placeholder="Select a Component"
              :options="components"
              select-label=""
              :multiple="false"
              :taggable="true"
              :hide-selected="true"
              :searchable="true"
              :close-on-select="true"
              class="mb-4"
            />
            <label
              for="components"
              class="form-label mt-2"
            >Components</label>
            <Multiselect
              id="components"
              v-model="multipleComponents"
              tag-placeholder="Not found"
              placeholder="Select a Component"
              :options="components"
              select-label=""
              :multiple="true"
              :taggable="true"
              :hide-selected="true"
              :searchable="true"
              :close-on-select="true"
              class="mb-4"
            />
          </div>
          <div
            v-if="component != 'Support Hour Package'"
            class="form-group col-12"
          >
            <label>{{ $t("from") }}</label>
            <date-picker
              v-model="selectedDateFrom"
              :editable="true"
              :clearable="false"
              type="date"
              lang="en"
              format="DD.MM.YYYY"
              value-type="date"
              :first-day-of-week="1"
              class="ml-3"
            />
            <label class="ml-4">{{ $t("to") }}</label>
            <date-picker
              v-model="selectedDateTo"
              :editable="true"
              :clearable="false"
              type="date"
              lang="en"
              format="DD.MM.YYYY"
              value-type="date"
              :first-day-of-week="1"
              class="ml-3"
            />
          </div>
        </form>
        <button
          slot="button"
          class="btn btn-primary mt-4 mb-5"
          :disabled="
            (!multipleComponentsToggle &&
              (!component || !selectedDateFrom || !selectedDateTo)) ||
              (multipleComponentsToggle &&
              (multipleComponents.length == 0 || !masterComponent))
          "
          @click="getChunksOfReports()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="file-alt"
          />Generate Report
        </button>
      </template>
      <div v-if="issueResult && Object.keys(issueResult).length > 0">
        <div
          v-for="(res, index) in issueResult"
          :key="`component-${index}`"
          style="font-size: 17px"
        >
          <div class="row">
            <span
              class="col-12"
              style="font-size: 20px"
            >
              <b>{{ index }}</b>
            </span>
          </div>
          <div class="row mb-2">
            <span
              class="col-12"
              style="font-size: 13px"
            >
              {{ $t("worklogCustomer.billableSupportActivities") }}
              {{ dateTime_date(selectedDateFrom) }} -
              {{ dateTime_date(selectedDateTo) }}
            </span>
          </div>
          <div class="row mb-3">
            <span
              class="col-12"
              style="font-size: 13px"
            >
              {{ $t("worklogCustomer.infoBill") }}:
              <a
                class="alt-primary-color"
                target="_blank"
                href="https://help.alturos.com/support/solutions/articles/29000040473"
              >https://help.alturos.com/support/solutions/articles/29000040473</a>
            </span>
          </div>
          <div class="row">
            <span
              class="col-1"
              style="font-size: 17px"
            >
              <b>JIRA Issue</b>
            </span>
            <span
              class="col-7"
              style="font-size: 17px"
            >
              <b>{{ $t("worklogCustomer.summary") }}</b>
            </span>
            <div class="row col-4">
              <span
                class="col-5"
                style="font-size: 17px"
              >
                <b>{{ $t("worklogCustomer.resolution") }}</b>
              </span>
              <span
                class="col-5"
                style="font-size: 17px"
              >
                <b>{{ $t("worklogCustomer.billingReason") }}</b>
              </span>
              <span
                class="col-2"
                style="font-size: 17px; text-align: end"
              >
                <b>{{ $t("worklogCustomer.effort") }}</b>
              </span>
            </div>
          </div>
          <div
            class="row mb-3"
            style="border-bottom: 1px solid black"
          >
            <span
              class="col-1"
              style="font-size: 14px"
            > FD Reporter </span>
            <span
              class="col-1"
              style="font-size: 14px"
            >
              {{ $t("worklogCustomer.date") }}
            </span>
            <span
              class="col-1"
              style="font-size: 14px"
            >
              {{ $t("worklogCustomer.worker") }}
            </span>
            <span
              class="col-5"
              style="font-size: 14px"
            >
              {{ $t("worklogCustomer.task") }}
            </span>
          </div>
          <div
            v-for="(projects, projectIndex) in res"
            :key="`prj-${index}-${projectIndex}`"
            style="font-size: 15px"
          >
            <div
              v-for="(issue, issueIndex) in projects"
              :key="`itm-${index}-${issueIndex}`"
            >
              <div class="row">
                <span class="col-1">
                  <a
                    class="alt-primary-color"
                    target="_blank"
                    :href="jiraUrl(issue.jiraIssueKey)"
                  ><b>{{ issue.jiraIssueKey }}</b></a>
                </span>
                <span
                  v-if="authenticationHasRole('scope_staff')"
                  class="col-7"
                  v-html="getFdTagSummary(issue.summary, true, issue.location)"
                />
                <span
                  v-else
                  class="col-7"
                  v-html="getFdTagSummary(issue.summary, true)"
                />
                <div
                  class="row col-4"
                  style="padding-right: 0"
                >
                  <span class="col-5">
                    <b>{{ issue.resolution }}</b>
                  </span>
                  <span class="col-5">
                    <b>{{ issue.billingReason }}</b>
                  </span>
                  <span
                    class="col-2"
                    style="text-align: end"
                  >
                    <b>{{
                      issue.customerApprovedEffort === -1
                        ? (
                          issue.worklogs
                            .map((x) => x.timeSpentInSeconds)
                            .reduce((sum, a) => sum + a, 0) / 3600
                        )
                          .toFixed(2)
                          .replace(".", ",")
                        : (issue.customerApprovedEffort / 3600)
                          .toFixed(2)
                          .replace(".", ",")
                    }}</b>
                  </span>
                </div>
              </div>
              <div
                v-for="(worklog, worklogIndex) in issue.worklogs"
                :key="`itm-${index}-${issueIndex}-${worklogIndex}`"
                style="font-size: 13px"
              >
                <div class="row mb-2">
                  <span
                    v-if="issue.hostingAndSupportAgreement && worklogIndex == 0"
                    class="col-1"
                  >
                    {{ issue.hostingAndSupportAgreement }}
                  </span>
                  <span
                    v-else
                    class="col-1"
                  >
                    {{ worklog.requester }}
                  </span>
                  <span class="col-1">
                    {{ dateTime_date(worklog.startDate) }}
                  </span>
                  <span class="col-1">
                    {{ worklog.authorUser.displayName }}
                  </span>
                  <span
                    v-if="authenticationHasRole('scope_staff')"
                    class="col-5"
                    style="white-space: pre-wrap"
                    v-html="getFdTagComment(worklog.comment, worklog.fdType)"
                  />
                  <span
                    v-else
                    class="col-5"
                    style="white-space: pre-wrap"
                    v-html="getFdTagComment(worklog.comment)"
                  />
                  <span class="col-1" />
                  <span class="col-1" />
                  <span class="col-1" />
                  <span
                    class="col-1"
                    style="text-align: end"
                  >
                    {{
                      (worklog.timeSpentInSeconds / 3600)
                        .toFixed(2)
                        .replace(".", ",")
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <span class="col-10" />
            <div
              class="row col-2"
              style="padding-right: 0; font-size: 15px"
            >
              <span class="col-8">
                <b>TOTAL</b>
              </span>
              <span
                class="col-4"
                style="text-align: end"
              >
                <b>{{ getSum(index) }} h</b>
              </span>
            </div>
          </div>
        </div>
      </div>
      <template v-else-if="issueResult && Object.keys(issueResult).length == 0">
        <div>
          {{ $t("noDataAvailable") }}
        </div>
      </template>
    </div>
  </Portlet>
</template>

<script>
import { dateTimeMixin } from "@/mixins/dateTimeMixin.js";
import DatePicker from "vue2-datepicker";
import { urlParseMixin } from "@/mixins/urlParseMixin";
import { mapGetters } from "vuex";
import { authenticationMixin } from "@/mixins/authenticationMixin";

export default {
  name: "CustomerWorklogReport",
  components: {
    Multiselect: () => import("vue-multiselect"),
    DatePicker,
  },
  mixins: [dateTimeMixin, urlParseMixin, authenticationMixin],
  props: {
    sMasterComponent: {
      type: String,
      required: false,
      default: null,
    },
    sComponent: {
      type: String,
      required: false,
      default: null,
    },
    sStartDate: {
      type: String,
      required: false,
      default: null,
    },
    sEndDate: {
      type: String,
      required: false,
      default: null,
    },
    onlyTasks: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      loading: false,
      selectedDateFrom: this.moment().subtract(1, "months").startOf("month"),
      selectedDateTo: this.moment().subtract(1, "months").endOf("month"),
      issueResult: null,
      progress: 0,
      componentLoading: false,
      components: null,
      component: "All",
      createExcel: true,
      exportLoading: false,
      multipleComponentsToggle: false,
      masterComponent: null,
      multipleComponents: [],
      reportInterval: null,
      reportData: null,
    };
  },
  computed: {
    ...mapGetters(["store_getJiraUrl"]),
  },
  beforeDestroy () {
    this.cancelInterval();
  },
  created () {
    if (
      this.sMasterComponent != null &&
      this.sMasterComponent != "null" &&
      this.sComponent &&
      this.sStartDate &&
      this.sEndDate
    ) {
      this.multipleComponentsToggle = true;
      this.masterComponent = this.sMasterComponent;
      this.multipleComponents = this.sComponent.split(",");
      this.selectedDateFrom = this.sStartDate;
      this.selectedDateTo = this.sEndDate;
      this.components = [];
      this.getComponents(true);
    } else if (this.sComponent && this.sStartDate && this.sEndDate) {
      this.component = this.sComponent;
      this.selectedDateFrom = this.sStartDate;
      this.selectedDateTo = this.sEndDate;
      this.components = [];
      this.multipleComponentsToggle = false;
      this.getComponents(true);
    } else {
      this.getComponents(false);
    }
  },
  methods: {
    changeMultipleComponentsToggle () {
      this.component = "All";
      this.multipleComponents = [];
      this.masterComponent = null;
    },
    excelExport (val) {
      this.createExcel = true;
      this.exportReport(val);
    },
    pdfExport (val) {
      var sd = this.dateTime_iso(this.selectedDateFrom);
      var ed = this.dateTime_iso(this.selectedDateTo);
      if (!this.multipleComponentsToggle) {
        let routeData = this.$router.resolve({
          path: `/customer-worklog-report/null/${encodeURIComponent(
            this.component
          )}/${encodeURIComponent(sd)}/${encodeURIComponent(ed)}/true/${val}`,
        });
        window.open(routeData.href, "_blank");
      } else {
        let routeData = this.$router.resolve({
          path: `/customer-worklog-report/${encodeURIComponent(
            this.masterComponent
          )}/${encodeURIComponent(
            this.multipleComponents.join(",")
          )}/${encodeURIComponent(sd)}/${encodeURIComponent(ed)}/true/${val}`,
        });
        window.open(routeData.href, "_blank");
      }
    },
    generateUrl () {
      var sd = this.dateTime_iso(this.selectedDateFrom);
      var ed = this.dateTime_iso(this.selectedDateTo);
      if (!this.multipleComponentsToggle) {
        let routeData = `${
          window.location.origin
        }/customer-worklog-report/null/${encodeURIComponent(
          this.component
        )}/${encodeURIComponent(sd)}/${encodeURIComponent(ed)}`;
        navigator.clipboard.writeText(routeData);
      } else {
        let routeData = `${
          window.location.origin
        }/customer-worklog-report/${encodeURIComponent(
          this.masterComponent
        )}/${encodeURIComponent(
          this.multipleComponents.join(",")
        )}/${encodeURIComponent(sd)}/${encodeURIComponent(ed)}`;
        navigator.clipboard.writeText(routeData);
      }
      this.$snotify.success("The URL has been copied to your clipboard.");
    },
    getFdTagSummary (text, bold, type) {
      if (text.includes("[") && text.includes("]")) {
        let sIndex = text.indexOf("[");
        let eIndex = text.indexOf("]");
        let id = text
          .substring(sIndex, eIndex)
          .replace("[", "")
          .replace("]", "");
        if (!isNaN(parseInt(id)) && !id.includes(" ")) {
          let url = `https://alturos.freshdesk.com/a/tickets/${id}`;
          let anchor = `<a class="alt-primary-color" target="_blank" href="${url}">${id}</a>]${
            type ? ` (${type})` : ""
          }`;
          return bold
            ? `<b>${text.replace(`${id}]`, anchor)}</b>`
            : `${text.replace(`${id}]`, anchor)}`;
        }
        return bold ? `<b>${text}</b>` : text;
      }
      return bold ? `<b>${text}</b>` : text;
    },
    getFdTagComment (text, type) {
      if (text.includes("FD#")) {
        var tmpText = text.replace(" ", "");
        var startingIndices = [];
        var indexOccurence = tmpText.indexOf("FD#", 0);

        while (indexOccurence >= 0) {
          startingIndices.push(indexOccurence + 3);
          indexOccurence = tmpText.indexOf("FD#", indexOccurence + 1);
        }

        for (var indx of startingIndices) {
          var tmpId = "";
          for (let i = indx; i < tmpText.length; i++) {
            if (!isNaN(parseInt(tmpText[i]))) {
              tmpId += tmpText[i];
            } else {
              break;
            }
          }
          if (tmpId != "") {
            let url = `https://alturos.freshdesk.com/a/tickets/${tmpId}`;
            let anchor = `<a class="alt-primary-color" target="_blank" href="${url}">${tmpId}</a>${
              type ? ` (${type})` : ""
            }`;
            text = text.replace(tmpId, anchor);
          }
        }
        return text;
      }

      return `${text}`;
    },
    jiraUrl (endpoint) {
      return this.urlParseMixin_combine(
        this.store_getJiraUrl,
        `browse/${endpoint}`
      );
    },
    getSum (index) {
      let count = 0;
      let values = this.issueResult;
      for (let proj in values[index]) {
        let wl = values[index][proj]
          .filter((x) => x.customerApprovedEffort === -1)
          .map((x) => x.worklogs);
        for (var item of wl) {
          let mp = item
            .map((x) => x.timeSpentInSeconds)
            .reduce((sum, a) => sum + a, 0);
          count += mp;
        }
        count += values[index][proj]
          .filter((x) => x.customerApprovedEffort != -1)
          .map((x) => x.customerApprovedEffort)
          .reduce((sum, a) => sum + a, 0);
      }
      return (count / 3600).toFixed(2).replace(".", ",");
    },
    getComponents (getReport) {
      this.componentLoading = true;
      this.axios
        .get(`/Issue/GetWorklogComponents`)
        .then((response) => {
          if (response.status == 200) {
            this.components = response.data;
            this.components.unshift("All");
          }
        })
        .finally(() => {
          this.componentLoading = false;
          if (getReport) {
            this.getChunksOfReports();
          }
        });
    },
    createInterval () {
      this.reportData = { percentage: 0 };
      this.reportInterval = window.setInterval(() => {
        this.getReportProgress();
      }, 2000);
    },
    cancelInterval () {
      this.reportData = null;
      window.clearInterval(this.reportInterval);
      this.reportInterval = null;
      this.reportData = null;
    },
    getReportProgress () {
      var user = this.authenticationGetUser();
      var email = user.details.profile.email;
      this.axios
        .get(`/Issue/GetWorklogReportData?user=${email}`)
        .then((response) => {
          if (response.status == 200 && response.data) {
            this.reportData = response.data;
            if (this.reportData.value) {
              if (this.reportData.value) {
                for (var item in this.reportData.value) {
                  var grouped = this.reportData.value[item].reduce(function (
                    r,
                    a
                  ) {
                    r[a.project] = r[a.project] || [];
                    r[a.project].push(a);
                    return r;
                  },
                  Object.create(null));
                  this.issueResult[item] = grouped;
                }
              }
              this.cancelInterval();
            } else if (this.reportData.error) {
              this.cancelInterval();
            }
          } else {
            this.reportData = null;
          }
        });
    },
    async getChunksOfReports () {
      if (!this.multipleComponentsToggle) {
        let comps =
          this.component == "All"
            ? this.components.filter(
                (x) =>
                  x != "All" &&
                  x != "Support Non-billable" &&
                  x != "Support Hour Package"
              )
            : [this.component];
        await this.generateReport(comps);
        this.loading = false;
      } else {
        await this.generateReport(
          this.multipleComponents,
          this.masterComponent
        );
        this.loading = false;
      }
    },
    async generateReport (itm, masterComponent) {
      var user = this.authenticationGetUser();
      var email = user.details.profile.email;
      this.issueResult = {};
      var req = {
        components: itm,
        startDate: this.selectedDateFrom,
        endDate: this.selectedDateTo,
        masterComponent: masterComponent,
        user: email,
      };

      await this.axios.post(`/Issue/GetCustomerWorklogReport`, req).then(() => {
        this.createInterval();
      });
    },
    exportReport (val) {
      var reports = [];
      this.exportLoading = true;
      for (var item in this.issueResult) {
        let r = {
          component: item,
          projects: [],
        };
        let eItem = this.issueResult[item];
        for (var project in eItem) {
          let p = {
            project: project,
            issues: [],
          };
          for (var issue of eItem[project]) {
            p.issues.push(issue);
          }
          r.projects.push(p);
        }
        reports.push(r);
      }
      let request = {
        reports: reports,
        createExcel: this.createExcel,
        createPdf: this.createPdf,
        startDate: this.selectedDateFrom,
        endDate: this.selectedDateTo,
        onlyTasks: val,
      };

      this.axios
        .post(`/Issue/ExportWorklogReport`, request)
        .then((response) => {
          if (response) {
            if (response && response.data) {
              for (var item of response.data) {
                const link = document.createElement("a");
                link.href =
                  "data:application/octet-stream;charset=utf-8;base64," +
                  item.base64;
                link.target = "_blank";
                link.download = item.filename;
                link.click();
              }
            }
          }
        })
        .finally(() => {
          this.exportLoading = false;
        });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
